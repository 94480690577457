import { ReactNode } from 'react';
import { MetaData } from 'components/atoms/PageMetaData/PageMetaData';
import HeroThirdPartyBrand from 'components/organisms/Hero/HeroThirdPartyBrand';
import Txt from 'components/atoms/Txt/Txt';

// Third party logos
import SaveTheStudentLogo from 'components/assets/thirdPartyLogos/save-the-student-logo.png';

interface LandingPage {
  [key: string]: {
    metaData: MetaData;
    leftColumnContent: ReactNode;
  };
}

const LANDING_PAGES: LandingPage = {
  'cash-app': {
    metaData: {
      title: 'Qmee – Take paid surveys with Qmee&apos;s cash app.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Take paid surveys with <Txt component="strong">Qmee&apos;s cash&nbsp;app</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  'earn-money': {
    metaData: {
      title: 'Qmee – Earn money with Qmee’s paid surveys.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Earn money with Qmee&apos;s <Txt component="strong">paid&nbsp;surveys</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  'market-research': {
    metaData: {
      title: 'Qmee – Earn real cash rewards with paid market research.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Earn real cash rewards with <Txt component="strong">paid market research</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  opinion: {
    metaData: {
      title: 'Qmee – Turn opinions into payment with paid surveys.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Turn opinions into payment with <Txt component="strong">paid&nbsp;surveys</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  'paid-surveys': {
    metaData: {
      title: 'Qmee – Take paid surveys to earn real cash rewards.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Take paid surveys to earn <strong>real cash</strong> rewards
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  polls: {
    metaData: {
      title: 'Qmee – Turn polls into payment with paid surveys.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Turn polls into payment with <Txt component="strong">paid&nbsp;surveys</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  questionnaire: {
    metaData: {
      title: 'Qmee – Take paid questionnaires to earn real cash rewards.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Take paid questionnaires to earn <Txt component="strong">real cash</Txt> rewards
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
  'save-the-student': {
    metaData: {
      title: 'Qmee – Share your Opinion & Shop to earn real cash rewards.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Earn money online with <strong>Qmee</strong>
        </Txt>
        <Txt component="p">Earn real cash rewards for browsing the web.</Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
        <HeroThirdPartyBrand logo={SaveTheStudentLogo} brandName="Save the Student" />
      </>
    ),
  },
  'side-hustle': {
    metaData: {
      title: 'Qmee – Make paid surveys your new side hustle.',
      description:
        'Qmee cash rewards, discounts & surveys. Search, earn and save today and put cash back in your wallet.',
    },
    leftColumnContent: (
      <>
        <Txt component="h1">
          Make paid surveys your new <Txt component="strong">side&nbsp;hustle</Txt>
        </Txt>
        <Txt component="p">
          Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt> with
          Qmee.
        </Txt>
      </>
    ),
  },
};

export default LANDING_PAGES;
